@font-face {
  font-family: 'AzoSans';
  src: url('./fonts/AzoSans-Thin.woff') format('woff'), url('./fonts/AzoSans-Thin.woff') format('woff2'),
    url('./fonts/AzoSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'AzoSans';
  src: url('./fonts/AzoSans-Light.woff') format('woff'), url('./fonts/AzoSans-Light.woff') format('woff2'),
    url('./fonts/AzoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'AzoSans';
  src: url('./fonts/AzoSans-Regular.woff') format('woff'), url('./fonts/AzoSans-Regular.woff') format('woff2'),
    url('./fonts/AzoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'AzoSans';
  src: url('./fonts/AzoSans-Medium.woff') format('woff'), url('./fonts/AzoSans-Medium.woff') format('woff2'),
    url('./fonts/AzoSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'AzoSans';
  src: url('./fonts/AzoSans-Bold.woff') format('woff'), url('./fonts/AzoSans-Bold.woff') format('woff2'),
    url('./fonts/AzoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'AzoSans';
  src: url('./fonts/AzoSans-Black.woff') format('woff'), url('./fonts/AzoSans-Black.woff') format('woff2'),
    url('./fonts/AzoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
